import { createQuery } from '../helpers'
import type {
  Organisation, CreateOrganisationParams,
  UpdateOrganisationParams,
  UpdateOrganisationGroupParams
} from '../../../types/organisation'
import type { PaginatedResponse, Query } from '../../../types/api'

/**
 * @var string
 * The properties to get back in any organisation response
 */
const returnProps = `
  id
  name
  description
  spaces
  groups
`

const getOrganisation: Query<Organisation, { organisationId: string }> = createQuery(`
  query getOrganisation($organisationId: ID!) {
    getOrganisation(id: $organisationId) { ${returnProps} }
  }
`)

const getOrganisations: Query<PaginatedResponse<Organisation>> = createQuery(`
  query getOrganisations($limit: Int, $next: String) {
    getOrganisations(limit: $limit, next: $next ) { 
      items {
        ${returnProps} 
      }
      next
      limit
    }
  }
`)

const createOrganisation: Query<Organisation, CreateOrganisationParams> = createQuery(`
  mutation createOrganisation(
    $name: String!,
    $description: String,
    $spaces: Int,
  ) {
    createOrganisation(
      name: $name,
      description: $description,
      spaces: $spaces,
    ) { ${returnProps} }
  }
`)

const updateOrganisation: Query<Organisation, UpdateOrganisationParams> = createQuery(`
  mutation editOrganisation(
    $organisationId: ID!,
    $name: String,
    $description: String,
    $spaces: Int,
  ) {
    editOrganisation(
      id: $organisationId,
      name: $name,
      description: $description,
      spaces: $spaces,
    ) { ${returnProps} }
  }
`)

const updateOrganisationGroups: Query<Organisation, UpdateOrganisationGroupParams> = createQuery(`
  mutation editOrganisation(
    $organisationId: ID!,
    $groups: AWSJSON,
  ) {
    editOrganisation(
      id: $organisationId,
      groups: $groups,
    ) { ${returnProps} }
  }
`)

export {
  getOrganisation,
  getOrganisations,
  createOrganisation,
  updateOrganisation,
  updateOrganisationGroups
}

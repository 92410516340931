/* eslint-disable max-len */
import React from 'react'
import { Panel, SelectPicker } from 'rsuite'
import Chart from 'react-apexcharts'

import { Icon } from '@rsuite/icons'

import {
  FaArrowUp
} from 'react-icons/fa'

import { usePageTitle } from '../../app/hooks'

import { RequireRole } from '../access/RequireRole'
import { UserRole } from '../../types/enums'
import { KpmCard } from './KpmCard'

// const stroke: 'smooth' | 'straight' | 'stepline' | ('smooth' | 'straight' | 'stepline')[] | undefined = 'smooth'
// const legendPosition: 'bottom' | 'left' | 'right' | 'top' | undefined = 'bottom'
const xaxisType: 'datetime' | 'category' | 'numeric' | undefined = 'datetime'
const carparkdata = ['82 Bathurst', 'Market Place', 'RMH'].map(
  (item: any) => ({ label: item, value: item })
)

/**
 *  Reporting page for organisation and global admins
 *  @return {React.ReactElement}
 */
function RevenueReports (): React.ReactElement {
  const barstate = {
    series: [{
      name: 'Casuals',
      data: [44, 55, 57, 58, 62],
    }, {
      name: 'Members',
      data: [76, 85, 101, 100, 108],
    }, {
      name: 'Subscriptions',
      data: [35, 41, 36, 38, 40],
    }],
    options: {
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: ['10 Feb', '11 Feb', '12 Feb', '13 Feb', '14 Feb'],
      },
      yaxis: {
        title: {
          text: '$ (thousands)',
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter (val:any) {
            return `$ ${val} thousands`
          },
        },
      },
    },
  }

  const linestate = {
    options: {
      stroke: {
        // curve: stroke,
      },
      chart: {
        zoom: {
          enabled: false,
        },
      },
      xaxis: {
        type: xaxisType,
      },
    },
    series: [
      {
        name: 'Series 1',
        data: [
          {
            x: '02-10-2017 GMT',
            y: 11000,
          },
          {
            x: '02-11-2017 GMT',
            y: 14000,
          },
          {
            x: '02-12-2017 GMT',
            y: 27500,
          },
          {
            x: '02-13-2017 GMT',
            y: 25402,
          },
          {
            x: '02-14-2017 GMT',
            y: 8987,
          },
          {
            x: '02-15-2017 GMT',
            y: 17012,
          },
        ],
      },
    ],
  }

  usePageTitle('Reporting')

  return (
    <RequireRole roles={[UserRole.ORGANISATION, UserRole.ADMIN]}>
      <Panel header={(<h2>Revenue</h2>)}>
        <div style={{ marginBottom: 20, display: 'flex', gap: 10 }}>
          <div style={{ width: 400 }}>
            Car park
            <SelectPicker defaultValue="82 Bathurst" data={carparkdata} block />
          </div>
        </div>
        <div className="chart-grid">
          <div className="chart-container">
            <h5>Weekly sales</h5>
            <Chart
              options={barstate.options}
              series={barstate.series}
              type="bar"
            />
          </div>

          <div className="chart-container">
            <h5>Weekly revenue</h5>
            <Chart
              options={linestate.options}
              series={linestate.series}
              type="line"
            />
          </div>

          <div className="chart-container">
            <h5>Average $$ per space</h5>
            <KpmCard
              body={(
                <div>
                  $11
                </div>
              )}
              footer={(
                <div>
                  <span style={{ color: '#00e388' }}>
                    <Icon as={FaArrowUp} />
                    {' '}
                    28.4%
                  </span>
                  {' '}
                  from yesterday
                </div>
              )}
            />
          </div>
        </div>
      </Panel>
    </RequireRole>
  )
}

export {
  RevenueReports
}

import React, { ReactElement } from 'react'
import { Modal, Button, ButtonToolbar } from 'rsuite'

export type Props = {
  header?: string,
  message?: string,
  onConfirm?: () => void,
  onClose: () => void,
}

/**
 * Contirmation modal component
 * @return {ReactElement}
 */
function ConfirmModal (props: Props): ReactElement {
  const {
    header = 'Are you sure?',
    message = '',
    onConfirm = () => {},
    onClose = () => {},
  } = props

  /**
   * Close the modal
   */
  const close = () => {
    onClose()
  }

  /**
   * Run the confirm action and close the modal
   */
  const confirm = () => {
    onConfirm()
    onClose()
  }

  return (
    <>
      <Modal.Header>
        <Modal.Title>{header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <ButtonToolbar>
          <Button onClick={confirm} appearance="primary">Confirm</Button>
          <Button onClick={close} appearance="subtle">Cancel</Button>
        </ButtonToolbar>
      </Modal.Footer>
    </>
  )
}

export {
  ConfirmModal
}

import { GateType, LocationStatus } from '../../types/enums'

const statuses = [
  {
    label: 'Active',
    value: LocationStatus.ACTIVE,
  },
  {
    label: 'Pending',
    value: LocationStatus.PENDING,
  },
  {
    label: 'Testing',
    value: LocationStatus.TESTING,
  },
  {
    label: 'Disabled',
    value: LocationStatus.DISABLED,
  },
]

const features = [
  {
    value: 'PARKING_SUBSCRIPTION',
    label: 'Parking subscriptions',
  },
  {
    value: 'PARKING_CASUAL',
    label: 'Casual parking (Beta)',
  },
  {
    value: 'PARKING_ACCESS_LIST',
    label: 'Parking access lists',
  },
]

const timezones = [
  'Australia/Adelaide',
  'Australia/Brisbane',
  'Australia/Darwin',
  'Australia/Hobart',
  'Australia/Perth',
  'Australia/Sydney',
].map(
  (item) => ({ label: item, value: item })
)

const gatetypes = [
  {
    label: 'Entry',
    value: GateType.ENTRY,
  },
  {
    label: 'Exit',
    value: GateType.EXIT,
  },
  {
    label: 'Access',
    value: GateType.ACCESS,
  },
]

export {
  statuses,
  timezones,
  features,
  gatetypes
}

/**
 * Convert a string of digits or a number into an array of numbers
 * @param {string|number} input
 * @return {number[]}
 */
const toDigits = (input: string|number): Array<number> => {
  const stringInput = typeof input === 'string' ? input : input.toString()
  return stringInput.split('').map((d: string) => parseInt(d, 10))
}

/**
 * Run a modulus 89 check on a string of digits. For use in validating ABNs
 * See https://abr.business.gov.au/Help/AbnFormat
 * @param {string} input - The string to check
 * @return {boolean} - True if the check passes
 */
const mod89 = (input: string): boolean => {
  const sum = toDigits(input.replace(/ /g, '')).reduce((a: number, d: number, i: number) => (
    i === 0
      ? a + (d - 1) * 10
      : a + d * (2 * i - 1)
  ), 0)
  return sum % 89 === 0
}

/**
 * Run a Luhn check on a string of digits. For use in validating credit card numbers
 * See https://en.wikipedia.org/wiki/Luhn_algorithm
 * @param {string} input - The string to check
 * @return {boolean} - True if the check passes
 */
const mod10 = (input: string): boolean => {
  const [check, ...digits] = toDigits(input.replace(/ /g, '')).reverse()
  let sum = 0
  digits.forEach((d: number, i: number) => {
    const a = d * (i % 2 === 0 ? 2 : 1)
    toDigits(a).forEach((b) => { sum += b })
  })
  return (10 - (sum % 10)) % 10 === check
}

/**
 * Parse a date object or string into year and month properties
 * @param {Date|string} date
 * @return {Object}
 */
const parseDate = (date: Date|string): { month: number, year: number } => {
  if (date instanceof Date) {
    return { month: date.getMonth() + 1, year: date.getFullYear() }
  }
  const [month, year] = date.split(/[-/]/).map((d) => parseInt(d, 10))
  return { month, year }
}

/**
 * Check that a date in the same month or later than another date
 * @param {Date|string} date
 * @param {Date} compare - A date to compare with
 * @return {boolean}
 */
const monthAfter = (date: Date|string, compare: Date): boolean => {
  const a = parseDate(date)
  const b = parseDate(compare)

  return a.year > b.year || (a.year === b.year && a.month >= b.month)
}

export {
  mod89,
  mod10,
  monthAfter
}

import React, { useEffect, useMemo } from 'react'

import { Form, InputPicker, Toggle } from 'rsuite'

import { useParams } from 'react-router-dom'

import type { FormProps } from '../types/form'
import * as schemas from './validation/schemas'
import { Validator } from './validation/validator'
import * as options from '../features/locations/LocationOptions'

import { ValidatedForm } from '../components'
import { useApi } from '../app/hooks'
import { getLocation } from '../services/graphql/queries'

/**
 * Location form component
 * @param {Props} props
 * @return {ReactElement}
 */
function LocationGateForm (props: FormProps) {
  const {
    formValue,
    onChange,
    onSubmit,
    error,
    formId,
  } = props

  const { locationId } = useParams()
  const locationQuery = useApi(getLocation)
  const locationData = locationQuery.getResponse()

  useEffect(() => {
    if (locationId) {
      locationQuery.sendRequest({ locationId })
    }
    return () => {
      locationQuery.cleanup()
    }
  }, [])

  const validator = new Validator({
    name: {
      model: schemas.required('Please enter a name for the gate'),
    },
    lane: {
      model: schemas.required('Please enter a Lane id'),
    },
  })

  const cameraGroups = useMemo(
    () => {
      if (!locationData.data || !locationData.data.cameraGroups) {
        return []
      }
      return locationData.data.cameraGroups.map(({ name }) => ({ label: name, value: name }))
    },
    [locationData]
  )

  return (
    <ValidatedForm
      fluid
      id={formId}
      validator={validator}
      formValue={formValue}
      onChange={onChange}
      onSubmit={onSubmit}
      error={error}
      autoComplete="off"
    >
      <Form.Group controlId="name">
        <Form.ControlLabel>Name</Form.ControlLabel>
        <Form.Control name="name" type="text" />
      </Form.Group>
      <Form.Group controlId="description">
        <Form.ControlLabel>Description</Form.ControlLabel>
        <Form.Control name="description" type="text" />
      </Form.Group>
      <Form.Group controlId="type">
        <Form.ControlLabel>Type</Form.ControlLabel>
        <Form.Control name="type" accepter={InputPicker} data={options.gatetypes} />
      </Form.Group>
      <Form.Group controlId="lane">
        <Form.ControlLabel>Lane ID</Form.ControlLabel>
        <Form.Control name="lane" type="text" />
      </Form.Group>
      <Form.Group controlId="cameraGroup">
        <Form.ControlLabel>Camera group</Form.ControlLabel>
        <Form.Control name="cameraGroup" accepter={InputPicker} data={cameraGroups} />
      </Form.Group>
      <Form.Group controlId="tidalLane">
        <Form.ControlLabel>Tidal lane</Form.ControlLabel>
        <Form.Control name="tidalLane" accepter={Toggle} checkedChildren="Yes" unCheckedChildren="No" />
      </Form.Group>
    </ValidatedForm>
  )
}

export {
  LocationGateForm
}

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { SelectPicker, Table } from 'rsuite'

import dayjs from 'dayjs'

import { getAdminLocations, getPricingReport, getPricings } from '../../services/graphql/queries'
import { useApi, usePaginatedApi } from '../../app/hooks'
import { DownloadTableToCsv, tableToCSV } from '../../components/DownloadTableToCsv'

const { Column, HeaderCell, Cell } = Table

const defaultColumns = [
  {
    key: 'plan',
    label: 'Plan',
  },
  {
    key: 'price',
    label: 'Price',
  },
  {
    key: 'location',
    label: 'Car park',
  },

  {
    key: 'activeCount',
    label: 'Active subscriptions',
  },

  {
    key: 'activeVehicles',
    label: 'Active vehicles',
  },

  {
    key: 'pendingCount',
    label: 'Pending subscriptions',
  },

  {
    key: 'pendingVehicle',
    label: 'Pending vehicles',
  },
]

function OccupancyByPlanReport () {
  const [sortColumn, setSortColumn] = React.useState('plan')
  const [sortType, setSortType] = React.useState()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [carpark, setCarpark] = React.useState<String | null>(null)
  const [plan, setPlan] = React.useState<String | null>(null)

  const locationsApi = usePaginatedApi({
    query: getAdminLocations,
    itemsPerPage: 1000,
    fetchParams: {
      organisation: process.env.REACT_APP_ORGANISATION_ID || '',
    },
    queryOptions: {
      cleanUpOnDismount: true,
      displayErrorAlerts: false,
    },
  })

  const plansApi = usePaginatedApi({
    query: getPricings,
    itemsPerPage: 1000,
    fetchParams: {},
    queryOptions: {
      cleanUpOnDismount: true,
      displayErrorAlerts: false,
    },
  })

  const dataQuery = useApi(getPricingReport)
  const res = dataQuery.getResponse()

  React.useEffect(() => {
    dataQuery.sendRequest({
      location: carpark,
      plan,
    })
    return () => {
      dataQuery.cleanup()
    }
  }, [carpark, plan])

  const data = res.data ? res.data.map((sub: any) => ({
    ...sub,
  })) : []

  const getData = () => {
    if (sortColumn) {
      const sortT = sortType || 'asc'
      return data.sort((a: any, b: any) => {
        const x = a[sortColumn]
        const y = b[sortColumn]

        if ((typeof x === 'string') && (typeof y === 'string')) {
          return (sortT === 'asc') ? x.localeCompare(y) : -1 * x.localeCompare(y)
        }

        if (sortT === 'asc') {
          return x - y
        }
        return y - x
      })
    }
    return data
  }

  const handleSortColumn = (sortCol: any, sortT: any) => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      setSortColumn(sortCol)
      setSortType(sortT)
    }, 500)
  }
  const ts = dayjs(new Date()).format('YYYYMMDD-HHmm')
  const dataExport = new File(
    tableToCSV(defaultColumns, getData()),
    `OccupancyByPlanReport_${ts}.csv`,
    { type: 'text/plain' }
  )
  return (
    <div>
      <div style={{
        display: 'flex', gap: 12, paddingBottom: 12, position: 'relative',
      }}
      >
        <div style={{ width: 200 }}>
          Car park
          <SelectPicker
            onChange={setCarpark}
            value={carpark}
            data={(locationsApi.currentPage || []).map((l) => ({ label: l.name, value: l.id }))}
          />
        </div>
        <div style={{ width: 200 }}>
          Plan
          <SelectPicker
            onChange={setPlan}
            value={plan}
            data={(plansApi.currentPage || []).map((l) => ({ label: l.name, value: l.id }))}
          />
        </div>
        <DownloadTableToCsv
          style={{ position: 'absolute', alignSelf: 'end', right: 0 }}
          url={URL.createObjectURL(dataExport)}
          fileName={dataExport.name}
          text="Download to file"
        />
      </div>

      <Table
        loading={res.loading || loading}
        hover={false}
        showHeader
        autoHeight
        data={getData()}
        sortColumn={sortColumn}
        sortType={sortType}
        defaultSortType="asc"
        onSortColumn={handleSortColumn}
        bordered
        headerHeight={40}
        rowHeight={46}
      >
        {defaultColumns.map((column) => {
          const { key, label, ...rest } = column
          return (
            <Column {...rest} key={key} flexGrow={1} fullText sortable>
              <HeaderCell>{label}</HeaderCell>
              <Cell dataKey={key} />
            </Column>
          )
        })}
      </Table>
    </div>
  )
}

export {
  OccupancyByPlanReport
}

import React, { ReactElement } from 'react'
import {
  Form, InputGroup, InputPicker, TagInput, TagPicker
} from 'rsuite'

import { ValidatedForm } from '../components'
import * as schemas from './validation/schemas'
import { Validator } from './validation/validator'
import type { FormProps } from '../types/form'
import * as options from '../features/locations/LocationOptions'
import { useUser } from '../app/hooks'
import { UserRole } from '../types/enums'

/**
 * Location form component
 * @param {Props} props
 * @return {ReactElement}
 */
function LocationForm (props: FormProps): ReactElement {
  const user = useUser()
  const {
    formValue,
    onChange,
    onSubmit,
    error,
    formId,
    mode,
  } = props

  const validator = new Validator({
    name: {
      model: schemas.required('Please enter a name for the location'),
    },
    timezone: {
      model: schemas.required('Please select the time zone for the location'),
    },
  })

  return (
    <ValidatedForm
      fluid
      id={formId}
      validator={validator}
      formValue={formValue}
      onChange={onChange}
      onSubmit={onSubmit}
      error={error}
      autoComplete="off"
    >
      <Form.Group controlId="name">
        <Form.ControlLabel>Name</Form.ControlLabel>
        <Form.Control name="name" type="text" />
      </Form.Group>
      <Form.Group controlId="description">
        <Form.ControlLabel>Description</Form.ControlLabel>
        <Form.Control name="description" type="text" />
      </Form.Group>
      <Form.Group controlId="address">
        <Form.ControlLabel>Street address</Form.ControlLabel>
        <Form.Control name="address" type="text" />
      </Form.Group>
      <Form.Group controlId="timezone">
        <Form.ControlLabel>Time zone</Form.ControlLabel>
        <Form.Control
          name="timezone"
          style={{ width: 300 }}
          accepter={InputPicker}
          data={options.timezones}
        />
      </Form.Group>
      <Form.Group controlId="geolocation">
        <Form.ControlLabel>Coordinates</Form.ControlLabel>
        <InputGroup style={{ width: 500 }}>
          <Form.Control name="lat" type="float" />
          <InputGroup.Addon>&deg;N</InputGroup.Addon>
          <Form.Control name="lon" type="float" />
          <InputGroup.Addon>&deg;E</InputGroup.Addon>
        </InputGroup>
      </Form.Group>
      <Form.Group controlId="features">
        <Form.ControlLabel>Features</Form.ControlLabel>
        <Form.Control name="features" accepter={TagPicker} data={options.features} />
      </Form.Group>
      {user.hasRole(UserRole.ADMIN)
      && (
        <Form.Group controlId="controllers">
          <Form.ControlLabel>Controller IDs</Form.ControlLabel>
          <Form.Control
            name="controllers"
            trigger={['Enter', 'Space', 'Comma']}
            data={[]}
            accepter={TagInput}
          />
        </Form.Group>
      )}
      {mode === 'new' && (
        <Form.Group controlId="geolocation">
          <Form.ControlLabel>Spaces</Form.ControlLabel>
          <InputGroup style={{ width: 250 }}>
            <InputGroup.Addon>Total</InputGroup.Addon>
            <Form.Control name="totalCapacity" type="int" />
          </InputGroup>
        </Form.Group>
      )}
      {mode === 'edit' && (
        <Form.Group controlId="geolocation">
          <Form.ControlLabel>Spaces</Form.ControlLabel>
          <InputGroup style={{ width: 500 }}>
            <InputGroup.Addon>Total</InputGroup.Addon>
            <Form.Control name="totalCapacity" type="int" />
            <InputGroup.Addon>Available</InputGroup.Addon>
            <Form.Control name="availableCapacity" type="int" />
          </InputGroup>
        </Form.Group>
      )}
      {mode === 'edit' && (
        <Form.Group controlId="status">
          <Form.ControlLabel>Status</Form.ControlLabel>
          <Form.Control name="status" accepter={InputPicker} data={options.statuses} />
        </Form.Group>
      )}
    </ValidatedForm>
  )
}

export {
  LocationForm
}

/* eslint-disable camelcase */
import { Amplify, API, graphqlOperation } from 'aws-amplify'

import { config } from '../config'
import { getQueryName } from './helpers'
import type { GraphQLResult } from '../../types/api'

Amplify.configure(config)

/**
 * Run a GraphQL API query
 * @param {ApiRequest} req - The request to run
 * @return {Promise}
 */
// FIXME: The return type should be ApiResponse<Type>, but cannot due to an apparent bug in TypeScript
// See https://github.com/microsoft/TypeScript/issues/26781
const run = async <Type>(query: string, params: Record<string, any>): Promise<Type|null> => {
  const queryName = getQueryName(query)
  const res = await API.graphql(graphqlOperation(query, params)) as GraphQLResult<Type>
  return res.data ? res.data[queryName] as Type : null
}

export {
  run
}

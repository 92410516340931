import React, { ReactElement, useEffect } from 'react'
import { Table, Tag } from 'rsuite'

import moment from 'moment-timezone'
import humanizeDuration from 'humanize-duration'

import { PaginationControls, TableData } from '../../components'

import { RowData } from '../../types/table'
import './LocationOccupancyTable.css'
import { SearchBar } from '../../components/SearchBar'
import { LocationVehicles } from '../../types/location'

type Props = {
  vehicles: LocationVehicles[]
  // eslint-disable-next-line no-unused-vars
  loading?: boolean
  nextPage?: () => void
  prevPage?: () => void
}

/**
 * Table listing vehicle regos for a location
 * @param {Props} props
 * @param {LocationVehicles[]} props.vehicles                - The vehicle to list
 * @param {boolean} props.loading                             - Whether table data is loading
 * @param {()=>{}} props.nextPage                             - Callback when next button is clicked
 * @param {()=>{}} props.prevPage                             - Callback when prev button is clicked
 * @return {React.ReactElement}
 */
function LocationOccupancyTable (props:Props):React.ReactElement {
  const {
    nextPage, prevPage, loading, vehicles,
  } = props
  const [searchValue, setSearchValue] = React.useState('')
  const [tableData, setTableData] = React.useState(vehicles)

  useEffect(() => {
    setTableData(vehicles)
  }, [loading])

  const handleSearchChange = (value: string) => {
    setSearchValue(value)
    setTableData(vehicles.filter((row: RowData) => {
      const str = `${row.rego}`
      return str.toLowerCase().includes(value.toLowerCase())
    }))
  }

  const renderDuration = (entrytime: string) => {
    const entry = moment.parseZone(entrytime)
    const current = moment()
    const ms = moment.duration(current.diff(entry)).asMilliseconds()
    let duration = humanizeDuration(
      ms,
      {
        units: ['d', 'h', 'm', 's'],
        conjunction: ' and ',
        serialComma: false,
        maxDecimalPoints: 0,
      }
    )
    if (ms < 0) {
      duration = '-'
    }
    return duration
  }

  const renderAccessType = (data: RowData): ReactElement => {
    const access: string = data.access || ''
    if (access === 'SUBSCRIPTION') {
      return (
        <Tag color="green">
          {access}
        </Tag>
      )
    }
    if (access === 'CASUAL') {
      return (
        <Tag color="blue">
          {access}
        </Tag>
      )
    }
    if (access === 'ACCESS') {
      return (
        <Tag color="orange">
          {access}
        </Tag>
      )
    }
    return <div>{access}</div>
  }

  return (
    <div className="location-occupancy-table">
      <SearchBar
        styles={{ marginBottom: 4 }}
        placeholder="Search rows"
        value={searchValue}
        onChange={handleSearchChange}
      />
      <Table
        wordWrap="break-word"
        data={tableData}
        loading={loading}
        autoHeight
      >
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Rego</Table.HeaderCell>
          <Table.Cell dataKey="rego" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Access Type</Table.HeaderCell>
          <TableData dataKey="access" content={renderAccessType} />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Entry</Table.HeaderCell>
          <Table.Cell>
            {(rowData) => (
              <div>
                {
                  rowData.entrytime === '2000-01-01T00:00:00.000+10:00'
                    ? '' : new Date(rowData.entrytime).toLocaleString()
                }
              </div>
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Duration</Table.HeaderCell>
          <Table.Cell>
            {(rowData) => (
              renderDuration(rowData.entrytime)
            )}
          </Table.Cell>
        </Table.Column>
      </Table>
      <div className="pagination">
        <PaginationControls nextPage={nextPage} prevPage={prevPage} />
      </div>
    </div>
  )
}

export {
  LocationOccupancyTable
}

import React, { ReactElement, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Nav } from 'rsuite'
import { Icon } from '@rsuite/icons'
import {
  FaHome,
  FaBuilding,
  FaUser,
  FaCar,
  FaMagic,
  FaHistory,
  FaUserShield
} from 'react-icons/fa'

import { useApi, useUser } from '../../app/hooks'
import { UserRole } from '../../types/enums'
import { getOrganisationAdminId } from '../../services/graphql/queries'

function AdminMenuItem ({ user }: any): ReactElement | null {
  const isAdmin = user.hasRole(UserRole.ADMIN)
  const isOperator = user.hasRole(UserRole.OPERATOR)
  const isAttendant = user.hasRole(UserRole.ATTENDANT)

  if (isAdmin) {
    return (
      <Nav.Item
        to="/admin/subscriptions/pending"
        icon={<Icon as={FaUserShield} />}
        as={Link}
      >
        Admin account
      </Nav.Item>
    )
  }
  if (isOperator) {
    return (
      <Nav.Item
        to="/operator/subscriptions"
        icon={<Icon as={FaUserShield} />}
        as={Link}
      >
        Operator account
      </Nav.Item>
    )
  }
  if (isAttendant) {
    return (
      <Nav.Item
        to="/attendant/users"
        icon={<Icon as={FaUserShield} />}
        as={Link}
      >
        Attendant account
      </Nav.Item>
    )
  }
  return null
}

/**
 * Menu items for the customer area
 * @return {ReactElement}
 */
function CustomerMenu (): ReactElement {
  const user = useUser()
  const path = useLocation().pathname

  const fetchOrganisationAdminId = useApi(getOrganisationAdminId)
  const fetchResponse = fetchOrganisationAdminId.getResponse()
  const userOrganisationAdminId = fetchResponse.data

  useEffect(() => {
    if (user.id) {
      fetchOrganisationAdminId.sendRequest({ id: user.id })
    }
    return fetchOrganisationAdminId.cleanup()
  }, [])

  return (
    <>
      <Nav.Item
        to="/"
        active={path === '/'}
        icon={<Icon as={FaHome} />}
        as={Link}
      >
        Dashboard
      </Nav.Item>

      <Nav.Item
        to="/vehicles"
        active={path.startsWith('/vehicles')}
        icon={<Icon as={FaCar} />}
        as={Link}
      >
        Vehicles
      </Nav.Item>
      <Nav.Item
        icon={<Icon as={FaMagic} />}
        to="/subscriptions"
        as={Link}
        active={path.startsWith('/subscriptions')}
      >
        Subscription
      </Nav.Item>
      <Nav.Item
        icon={<Icon as={FaHistory} />}
        to="/visits"
        as={Link}
        active={path.startsWith('/visits')}
      >
        History
      </Nav.Item>
      <hr />
      <Nav.Item
        to="/account"
        active={path.startsWith('/account')}
        icon={<Icon as={FaUser} />}
        as={Link}
      >
        My account
      </Nav.Item>

      {userOrganisationAdminId && (
        <Nav.Item
          to={`/organisations/${userOrganisationAdminId}`}
          icon={<Icon as={FaBuilding} />}
          as={Link}
        >
          Organisation account
        </Nav.Item>
      )}

      <AdminMenuItem user={user} />
    </>
  )
}

export {
  CustomerMenu
}

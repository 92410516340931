import React, { ReactElement, useState } from 'react'
import { Panel } from 'rsuite'
import { useNavigate } from 'react-router-dom'

import {
  useApi, useModal, usePageTitle, useUser
} from '../../app/hooks'
import { Subscription, SubscriptionDTO } from '../../types/subscription'
import { AdminSubscriptionTable } from './AdminSubscriptionsTable'
import { cancelSubscription, getAdminSubscriptions } from '../../services/graphql/queries'
import { SortOrder, SubscriptionStatus } from '../../types/enums'
import { usePaginatedApi } from '../../app/hooks/paginatedApi'
import { Modal } from '../../components'
import { AdminCancelSubscriptionModal } from './AdminCancelSubscriptionModal'
import { replaceInPaginatedWithId } from '../../helpers'

/**
 * An admin's view of subscriptions
 */
function AdminSubscriptionList ():ReactElement {
  usePageTitle('Subscriptions')
  const navigate = useNavigate()
  const user = useUser()

  const cancelModal = useModal()

  const cancelQuery = useApi(cancelSubscription)
  const cancelRes = cancelQuery.getResponse()

  const [selectedSubscription, setSelectedSubscription] = useState<SubscriptionDTO|null>(null)

  /**
   * Cb when admin clicks on the cancel subscription action in a table row
   * @param {SubscriptionDTO} subscription - The subscription to be cancelled
   */
  const onCancel = (subscription: SubscriptionDTO) => {
    setSelectedSubscription(subscription)
    cancelModal.show()
  }

  const pendingSubscriptionQuery = usePaginatedApi({
    query: getAdminSubscriptions,
    fetchParams: {
      params: {
        status: [SubscriptionStatus.PENDING],
        sortDate: SortOrder.DESC,
      },
    },
    itemsPerPage: 10,
    queryOptions: {
      cleanUpOnDismount: true,
      displayErrorAlerts: false,
    },
  })

  const pendingSubscriptionsPage = pendingSubscriptionQuery.currentPage

  const otherSubscriptionQuery = usePaginatedApi({
    query: getAdminSubscriptions,
    fetchParams: {
      params: {
        status: [
          SubscriptionStatus.ACTIVE,
          SubscriptionStatus.REJECTED,
          SubscriptionStatus.CANCELLED,
          SubscriptionStatus.UNPAID,
        ],
        sortDate: SortOrder.DESC,
      },
    },
    itemsPerPage: 20,
    queryOptions: {
      cleanUpOnDismount: false,
      displayErrorAlerts: false,

    },
  })

  /**
   *  Cb when users click on the confirm button in the confirm cancellation modal
   */
  const onCancelConfirm = () => {
    if (selectedSubscription) {
      const { setData } = otherSubscriptionQuery.apiHook
      cancelQuery.sendRequest({ subscriptionId: selectedSubscription.id }, (data: any) => {
        setData((oldPaginated) => replaceInPaginatedWithId(oldPaginated, data))
        cancelModal.hide()
        return data
      })
    }
  }

  const otherSubscriptionsPage = otherSubscriptionQuery.currentPage

  /**
   * Cb for when user clicks on the review button of a
   * pending subscription
   * @param {Subscription} subscription - the subscription object of the row that was clicked
   */
  const reviewCb = (subscription:Subscription) => {
    navigate(`/${user.getRoleForRoute()}/subscriptions/review/${subscription.id}`)
  }

  /**
   * Cb for when user clicks on the edit action for a subscription
   * @param {Subscription} subscription - The subscription to be edited
   */
  const editCb = (subscription:Subscription) => {
    navigate(`/${user.getRoleForRoute()}/subscriptions/edit/${subscription.id}`)
  }

  return (
    <div>
      <Panel header={<h2>Pending subscriptions</h2>}>
        <AdminSubscriptionTable
          subscriptions={pendingSubscriptionsPage || []}
          loading={pendingSubscriptionQuery.loading}
          cancelCb={onCancel}
          reviewCb={reviewCb}
          nextPage={pendingSubscriptionQuery.next || undefined}
          prevPage={pendingSubscriptionQuery.prev || undefined}
          editCb={editCb}
        />
      </Panel>
      <Panel header={<h2>Customer subscriptions</h2>}>
        <AdminSubscriptionTable
          subscriptions={otherSubscriptionsPage || []}
          cancelCb={onCancel}
          editCb={editCb}
          loading={otherSubscriptionQuery.loading}
          nextPage={otherSubscriptionQuery.next || undefined}
          prevPage={otherSubscriptionQuery.prev || undefined}
        />
      </Panel>
      <Modal hook={cancelModal}>
        <AdminCancelSubscriptionModal
          onConfirm={onCancelConfirm}
          onClose={cancelModal.hide}
          loading={cancelRes.loading}
        />
      </Modal>
    </div>
  )
}

export {
  AdminSubscriptionList
}

import React, { ReactElement } from 'react'

import { usePageTitle } from '../../app/hooks'
import { UserDetails } from './UserDetails'
import { PaymentDetails } from './PaymentDetails'

/**
 * Account details component
 * @return {ReactElement}
 */
function AccountDetails (): ReactElement {
  usePageTitle('My account')

  return (
    <>
      <UserDetails />
      <PaymentDetails />
    </>
  )
}

export {
  AccountDetails
}

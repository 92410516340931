import { useState } from 'react'

/**
 * A hook for managing modal state
 * @return {Object} - The modal state and show/hide functions
 */
const useModal = () => {
  const [open, setOpen] = useState(false)
  const [data, setData] = useState(null)

  return {
    open,
    data,
    show: (d = null) => {
      setData(d)
      setOpen(true)
    },
    hide: () => {
      setData(null)
      setOpen(false)
    },
  }
}

export {
  useModal
}

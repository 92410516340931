const DEFAULT_PHONE_PREFIX = '+61'

/**
 * Add an international dialing code to the start of a local phone number
 * @param {string} phone - The phone number to normalise
 * @return {string}
 */
const normalisePhoneNumber = (phone: string): string => (
  phone.replace(/^0/, DEFAULT_PHONE_PREFIX)
)

/**
 * Normalise an email address to be all lowercase
 * @param {string} email - The email address to normalise
 * @return {string}
 */
const normaliseEmailAddress = (email: string): string => (
  email.toLowerCase()
)

export {
  normalisePhoneNumber,
  normaliseEmailAddress
}

import React from 'react'
import { Panel, SelectPicker } from 'rsuite'
import Chart from 'react-apexcharts'

import { usePageTitle } from '../../app/hooks'

import { RequireRole } from '../access/RequireRole'
import { UserRole } from '../../types/enums'

const legendPosition: 'bottom' | 'left' | 'right' | 'top' | undefined = 'bottom'
const xaxisType: 'datetime' | 'category' | 'numeric' | undefined = 'datetime'
const carparkdata = ['82 Bathurst', 'Market Place', 'RMH'].map(
  (item: any) => ({ label: item, value: item })
)

/**
 *  Reporting page for organisation and global admins
 *  @return {React.ReactElement}
 */
function OccupancyReports (): React.ReactElement {
  const state = {
    options: {
      legend: {
        position: legendPosition,
      },
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: true,
          },
        },
      },
      plotOptions: {
        pie: {
          startAngle: 0,
          endAngle: 360,
          expandOnClick: true,
          offsetX: 0,
          offsetY: 0,
          customScale: 1,
          dataLabels: {
            offset: 0,
            minAngleToShowLabel: 10,
          },
          donut: {
            size: '65%',
            background: 'transparent',
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: '22px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
                color: undefined,
                offsetY: -10,
                formatter (val: any) {
                  return val
                },
              },
              value: {
                show: true,
                fontSize: '16px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 400,
                color: undefined,
                offsetY: 16,
                formatter (val: any) {
                  return val
                },
              },
              total: {
                show: true,
                showAlways: true,
                label: 'Total',
                fontSize: '22px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
                color: '#373d3f',
                formatter (w: { globals: { seriesTotals: any[] } }) {
                  return w.globals.seriesTotals.reduce((a: any, b: any) => a + b, 0)
                },
              },
            },
          },
        },
      },
      dataLabels: {
        formatter: (
          val: number
        ) => `${val.toFixed(1)}%`,
      },
      labels: ['Casual', 'Member', 'Subscriber'],
    },
    series: [44, 55, 41],
  }

  const vacancystate = {
    options: {
      legend: {
        position: legendPosition,
      },
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: true,
          },
        },
      },
      plotOptions: {
        pie: {
          startAngle: 0,
          endAngle: 360,
          expandOnClick: true,
          offsetX: 0,
          offsetY: 0,
          customScale: 1,
          dataLabels: {
            offset: 0,
            minAngleToShowLabel: 10,
          },
          donut: {
            size: '65%',
            background: 'transparent',
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: '22px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
                color: undefined,
                offsetY: -10,
                formatter (val: any) {
                  return val
                },
              },
              value: {
                show: true,
                fontSize: '16px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 400,
                color: undefined,
                offsetY: 16,
                formatter (val: any) {
                  return val
                },
              },
              total: {
                show: true,
                showAlways: true,
                label: 'Total',
                fontSize: '22px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
                color: '#373d3f',
                formatter (w: { globals: { seriesTotals: any[] } }) {
                  return w.globals.seriesTotals.reduce((a: any, b: any) => a + b, 0)
                },
              },
            },
          },
        },
      },
      dataLabels: {
        formatter: (
          val: number
        ) => `${val.toFixed(1)}%`,
      },
      labels: ['Vacant', 'In use'],
    },
    series: [321, 101],
  }

  const linestate = {
    options: {
      stroke: {
        // curve: stroke,
      },
      chart: {
        zoom: {
          enabled: false,
        },
      },
      xaxis: {
        type: xaxisType,
      },
    },
    series: [
      {
        name: 'Series 1',
        data: [
          {
            x: '02-10-2017 GMT',
            y: 82,
          },
          {
            x: '02-11-2017 GMT',
            y: 198,
          },
          {
            x: '02-12-2017 GMT',
            y: 132,
          },
          {
            x: '02-13-2017 GMT',
            y: 156,
          },
          {
            x: '02-14-2017 GMT',
            y: 54,
          },
          {
            x: '02-15-2017 GMT',
            y: 61,
          },
        ],
      },
    ],
  }

  usePageTitle('Reporting')

  return (
    <RequireRole roles={[UserRole.ORGANISATION, UserRole.ADMIN]}>
      <Panel header={(<h2>Occupancy</h2>)}>
        <div style={{ marginBottom: 20, display: 'flex', gap: 10 }}>
          <div style={{ width: 400 }}>
            Car park
            <SelectPicker defaultValue="82 Bathurst" data={carparkdata} block />
          </div>
        </div>
        <div className="chart-grid">
          <div className="chart-container">
            <h5>Occupancy by user type</h5>
            <Chart
              options={state.options}
              series={state.series}
              type="donut"
            />
          </div>

          <div className="chart-container">
            <h5>Total occupancy</h5>
            <Chart
              options={vacancystate.options}
              series={vacancystate.series}
              type="donut"
            />
          </div>

          <div className="chart-container">
            <h5>Weekly occupancy levels</h5>
            <Chart
              options={linestate.options}
              series={linestate.series}
              type="area"
            />
          </div>
        </div>
      </Panel>
    </RequireRole>
  )
}

export {
  OccupancyReports
}

import React, { ReactElement, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Panel } from 'rsuite'

import { useApi, usePaginatedApi } from '../../app/hooks'
import { getLocation, getLocationVehicles } from '../../services/graphql/queries'
import { LocationOccupancyTable } from './LocationOccupancyTable'

/**
 * Location Occupancy
 * @return {ReactElement}
 */
function LocationOccupancy (): ReactElement {
  const { locationId } = useParams()

  const fetchApi = useApi(getLocation)
  const fetchRes = fetchApi.getResponse()

  useEffect(() => {
    if (locationId) {
      fetchApi.sendRequest({ locationId })
    }
    return () => {
      fetchApi.cleanup()
    }
  }, [])

  const locationQuery = usePaginatedApi({
    query: getLocationVehicles,
    fetchParams: {
      location: locationId || '',
    },
    itemsPerPage: 20,
    queryOptions: {
      cleanUpOnDismount: true,
      displayErrorAlerts: false,
    },
  })

  /**
   * Render title for location occupancy
   * @param {Location} location
   * @return {ReactElement}
   */
  const renderName = (location: any): ReactElement => (
    <div>
      List of vehicles in&nbsp;
      {location.name}
      &nbsp; car park at&nbsp;
      {new Date().toLocaleString()}
    </div>
  )

  const locationQueryPage = locationQuery.currentPage

  return (
    <Panel
      className="location-occupancy-list location-panel"
      header={(
        <h2>Car park occupancy</h2>
      )}
    >
      { fetchRes.data && renderName(fetchRes.data) }
      <br />
      <LocationOccupancyTable
        vehicles={locationQueryPage || []}
        loading={locationQuery.loading}
        nextPage={locationQuery.next || undefined}
        prevPage={locationQuery.prev || undefined}
      />
    </Panel>
  )
}

export {
  LocationOccupancy
}

import { createQuery } from '../helpers'
import type {
  CreateGateParams, GateConfig, RemoveGateParams, UpdateGateParams, GetLocationGateParams, Gate
} from '../../../types/gate'

import type { PaginatedResponse, Query } from '../../../types/api'

const returnProps = `
  id
  name
  description
  type
  location
  lane
  status
  cameraGroup
  tidalLane
  controllerStatus {
    versions
  }
`

const getLocationGates: Query<PaginatedResponse<GateConfig>, GetLocationGateParams > = createQuery(`
  query getLocationGates($locationId: ID!, $limit: Int, $next: String) {
    getLocationGates (location: $locationId, limit: $limit, next: $next) { 
      items {
        ${returnProps} 
      }
      next
      limit
    }
  }
`)

const getGateConfig: Query<Gate, { gateId: string }> = createQuery(`
  query getGateConfig($gateId: ID!) {
    getGateConfig (id: $gateId) { ${returnProps} }
  }
`)

const createGate: Query<GateConfig, CreateGateParams> = createQuery(`
  mutation createGate(
    $name: String!,
    $description: String,
    $type: GateType!,
    $location: ID!,
    $lane: String!,
    $cameraGroup: String,
    $tidalLane: Boolean,
  ) {
    createGate(
      name: $name,
      description: $description,
      type: $type,
      location: $location,
      lane: $lane,
      cameraGroup: $cameraGroup,
      tidalLane: Boolean,
    ) { ${returnProps} }
  }
`)

const updateGate: Query<Gate, UpdateGateParams> = createQuery(`
  mutation editGate(
    $gateId: ID!,
    $name: String,
    $description: String,
    $type: GateType!,
    $lane: String,
    $cameraGroup: String,
    $tidalLane: Boolean,
  ) {
    editGate(
      id: $gateId,
      name: $name,
      description: $description,
      type: $type,
      lane: $lane,
      cameraGroup: $cameraGroup,
      tidalLane: $tidalLane,
    ) { ${returnProps} }
  }
`)

const removeGate: Query<void, RemoveGateParams> = createQuery(`
  mutation removeGate($gateId: ID!) {
    removeGate(id: $gateId)
  }
`)

export {
  getLocationGates,
  createGate,
  updateGate,
  getGateConfig,
  removeGate
}

import React, { ReactElement } from 'react'
import { Routes, Route } from 'react-router-dom'

import { RequireRole } from '../../features/access/RequireRole'
import { AdminEditSubscription } from '../../features/subscriptions/AdminEditSubscription'
import { AdminReviewSubscription } from '../../features/subscriptions/AdminReviewSubscription'
import { AdminSubscriptionList } from '../../features/subscriptions/AdminSubscriptionsList'
import { UserRole } from '../../types/enums'

/**
 * SubscriptionsPage route
 * @return {ReactElement}
 */
function SubscriptionsPage (): ReactElement {
  return (
    <RequireRole roles={[UserRole.OPERATOR]}>
      <Routes>
        <Route path="/" element={<AdminSubscriptionList />} />
        <Route path="/review/:id" element={<AdminReviewSubscription />} />
        <Route path="/edit/:id" element={<AdminEditSubscription />} />
      </Routes>
    </RequireRole>
  )
}

export {
  SubscriptionsPage
}

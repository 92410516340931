import { Query } from '../../../types/api'
import { ReportingDataDTO } from '../../../types/reporting'
import { createQuery } from '../helpers'

const returnProps = `
  subscriptionsTotal
  subscriptionBreakdown {
    location
    plan
    subscriptionCount
  }
  subscriptionsAtCarpark
  customersTotal
  customersWithSubscriptionTotal
`

export const getReportingData: Query<ReportingDataDTO> = createQuery(`
  query getReportingData {
    getReportingData {
      ${returnProps}
    }
  }
`)

export const getSubscribersReport: Query<any, {
  location: String | null, startDate: String | null, endDate: String | null
}> = createQuery(`
  query getSubscribersReport($location: ID, $startDate: String, $endDate: String) {
    getSubscribersReport(location: $location, startDate: $startDate, endDate: $endDate) {
      id
      endDate
      location {
        name
      }
      plan {
        name
      }
      user {
        givenName
        familyName
        email
      }
      vehicles
      startDate
      status
    }
  }
`)

export const getMembersReport: Query<any, {
  status: String | null, payment: String | null, login: String | null
}> = createQuery(`
  query getMembersReport($status: String, $payment: String, $login: String) {
    getMembersReport(status: $status, payment: $payment, login: $login) {
      name
      email
      subscriptions
      status
      vehicles
      payment
      login
    }
  }
`)

export const getPricingReport: Query<any, {
  plan: String | null, location: String | null
}> = createQuery(`
  query getPricingReport($plan: String, $location: String) {
    getPricingReport(plan: $plan, location: $location) {
      plan
      price
      location
      activeCount
      activeVehicles
      pendingCount
      pendingVehicle
    }
  }
`)

export const getVehicleAccessReport: Query<any, {
  location: String | null, entry: String | null, exit: String | null
}> = createQuery(`
  query getVehicleAccessReport($location: ID, $entry: String, $exit: String) {
    getVehicleAccessReport(location: $location, entry: $entry, exit: $exit) {
      rego
      carpark
      access
      entrytime
      exittime
      name
      email
      status
      state
      make
      model
    }
  }
`)

export const getDeclinedPaymentsReport: Query<any, {
  from: String | null, to: String | null
}> = createQuery(`
  query getDeclinedPaymentsReport($from: String, $to: String) {
    getDeclinedPaymentsReport(from: $from, to: $to) {
      name
      email
      phone
      amount
      type
      status
      created
    }
  }
`)

import React from 'react'

import './KpmCard.css'

type KpmCardProps = {
  body: React.ReactElement,
  footer: React.ReactElement,
  /** Whether to display the component's loading state */
  loading?: boolean;
}

export function KpmCard (props:KpmCardProps): React.ReactElement {
  const {
    body, footer, loading,
  } = props
  return (
    <div className="kpm-item">
      <div className="body">
        {loading ? 'Loading...' : body}
      </div>
      <div className="footer">
        {loading ? 'Loading...' : footer}
      </div>
    </div>
  )
}

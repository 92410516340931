import React, { ReactElement } from 'react'
import { Routes, Route } from 'react-router-dom'

import { RequireRole } from '../../features/access/RequireRole'
import { UserRole } from '../../types/enums'
import { OccupancyReports } from '../../features/admin-dashboard/OccupancyDashboard'
import { RevenueReports } from '../../features/admin-dashboard/RevenueDashboard'

/**
 * ReportsPage route
 * @return {ReactElement}
 */
function DashboardPage (): ReactElement {
  return (
    <RequireRole roles={[UserRole.ADMIN, UserRole.ORGANISATION, UserRole.OPERATOR]}>
      <Routes>
        <Route
          path="/occupancy"
          element={<OccupancyReports />}
        />
        <Route
          path="/revenue"
          element={<RevenueReports />}
        />
      </Routes>
    </RequireRole>
  )
}

export {
  DashboardPage
}

/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-return-assign */
import React, { ReactElement, useState } from 'react'
import {
  Input, Panel, Table, Button, Loader
} from 'rsuite'

import './CustomerSearch.css'

import { useApi } from '../../app/hooks'
import { getVehicleByRego } from '../../services/graphql/queries'
import TariffOverrideModal from './TariffOverrideModal'
import { DefinitionList } from '../../components'

const { Column, HeaderCell, Cell } = Table

/**
 * Component to create Gate
 * @returns {ReactElement}
 */
function CustomerSearch () : ReactElement {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const [value, setValue] = useState<string>('')

  const vehicle = useApi(getVehicleByRego, {
    displayErrorAlerts: false,
    cleanUpOnDismount: true,
  })

  const vehicleRes = vehicle.getResponse()
  const vehicleData = vehicleRes.data

  const performSearch = async () => {
    if (value.length === 0) return
    vehicle.reset()
    await vehicle.sendRequest({ rego: value })
  }

  const handleClose = () => {
    performSearch()
    setOpen(false)
  }

  const memberData = vehicleData ? {
    Name: `${vehicleData?.givenName} ${vehicleData?.familyName}`,
    Email: vehicleData?.email,
    Phone: vehicleData?.phoneNumber,
  } : {}

  return (
    <>
      <Panel
        header={(
          <h2>Vehicle search</h2>
        )}
      >
        <div style={{ marginBottom: 16 }}>
          <Input
            placeholder="Vehicle registration..."
            value={value}
            onChange={(v) => setValue(v)}
            style={{ marginBottom: 8, textTransform: 'uppercase' }}
          />
          <Button
            disabled={value.length === 0}
            appearance="primary"
            onClick={performSearch}
          >
            Search
          </Button>
          {
            (vehicleRes.success && vehicleData)
              ? (
                <Button
                  disabled={value.length === 0}
                  style={{ marginLeft: 8, textTransform: 'uppercase' }}
                  onClick={handleOpen}
                >
                  Apply tariff override
                </Button>
              ) : null
          }
        </div>
        <div>
          {(vehicleRes.loading) && (
            <Loader>
              {' '}
              Loading...
            </Loader>
          )}
          {(vehicleRes.success && !vehicleData) && (<p>No match found.</p>)}
        </div>
      </Panel>
      {
        (vehicleRes.success && vehicleData) && (
          <>
            <Panel header={(<h2>Member details</h2>)} className="result-panel">
              <DefinitionList loading={vehicleRes.loading} data={memberData} />
            </Panel>
            <Panel header={(<h2>Vehicle details</h2>)} className="result-panel">
              <Table
                autoHeight
                data={[vehicleData]}
              >
                <Column>
                  <HeaderCell>Registration</HeaderCell>
                  <Cell dataKey="rego" />
                </Column>

                <Column>
                  <HeaderCell>State</HeaderCell>
                  <Cell dataKey="state" />
                </Column>
                <Column>
                  <HeaderCell>Make</HeaderCell>
                  <Cell dataKey="make" />
                </Column>
                <Column>
                  <HeaderCell>Model</HeaderCell>
                  <Cell dataKey="model" />
                </Column>
                <Column fullText width={300}>
                  <HeaderCell>Flags</HeaderCell>
                  <Cell dataKey="flags">
                    {(rowData) => (
                      <div>{rowData.flags?.join(', ')}</div>
                    )}
                  </Cell>
                </Column>
              </Table>
            </Panel>
            <Panel header={(<h2>Subscription history</h2>)} className="result-panel">
              <Table
                data={vehicleData.subscriptions}
                autoHeight
              >
                <Column fullText flexGrow={0.2}>
                  <HeaderCell>Name</HeaderCell>
                  <Cell dataKey="plan" />
                </Column>
                <Column fullText flexGrow={0.2}>
                  <HeaderCell>Car park</HeaderCell>
                  <Cell dataKey="location" />
                </Column>
                <Column flexGrow={0.25}>
                  <HeaderCell>Start date</HeaderCell>
                  <Cell dataKey="startDate">
                    {(rowData) => (
                      <div>{new Date(rowData.startDate).toLocaleDateString()}</div>
                    )}
                  </Cell>
                </Column>
                <Column flexGrow={0.25}>
                  <HeaderCell>End date</HeaderCell>
                  <Cell dataKey="endDate">
                    {(rowData) => (
                      <div>{rowData.endDate ? new Date(rowData.endDate).toLocaleDateString() : ''}</div>
                    )}
                  </Cell>
                </Column>
                <Column flexGrow={0.25}>
                  <HeaderCell>Status</HeaderCell>
                  <Cell dataKey="status" />
                </Column>
                <Column flexGrow={0.15}>
                  <HeaderCell>Vehicles</HeaderCell>
                  <Cell dataKey="vehicles" />
                </Column>
              </Table>
            </Panel>
            <Panel header={(<h2>Tariff overrides</h2>)} className="result-panel">
              <Table
                data={vehicleData.overrides}
                autoHeight
              >
                <Column>
                  <HeaderCell>Car park</HeaderCell>
                  <Cell dataKey="location" />
                </Column>
                <Column fullText width={200}>
                  <HeaderCell>Tariff applied</HeaderCell>
                  <Cell dataKey="tariff" />
                </Column>
                <Column width={150}>
                  <HeaderCell>Start time</HeaderCell>
                  <Cell dataKey="startTime">
                    {(rowData) => (
                      <div>{new Date(rowData.startTime).toLocaleString()}</div>
                    )}
                  </Cell>
                </Column>
                <Column width={150}>
                  <HeaderCell>End time</HeaderCell>
                  <Cell dataKey="endTime">
                    {(rowData) => (
                      <div>{rowData.endTime ? new Date(rowData.endTime).toLocaleString() : ''}</div>
                    )}
                  </Cell>
                </Column>
                <Column fullText width={300}>
                  <HeaderCell>Comment</HeaderCell>
                  <Cell dataKey="comment" />
                </Column>
              </Table>
            </Panel>
            <Panel header={(<h2>Parking history</h2>)} className="result-panel">
              <Table
                data={vehicleData.parkingHistory}
                autoHeight
              >
                <Column>
                  <HeaderCell>Car park</HeaderCell>
                  <Cell dataKey="location" />
                </Column>
                <Column width={150}>
                  <HeaderCell>Entry time</HeaderCell>
                  <Cell dataKey="startTime">
                    {(rowData) => (
                      <div>
                        {
                          rowData.entrytime === '2000-01-01T00:00:00.000+10:00'
                            ? '' : new Date(rowData.entrytime).toLocaleString('')
                        }
                      </div>
                    )}
                  </Cell>
                </Column>
                <Column width={150}>
                  <HeaderCell>Exit time</HeaderCell>
                  <Cell dataKey="endTime">
                    {(rowData) => (
                      <div>
                        {
                          rowData.exittime === '2000-01-01T00:00:00.000+10:00'
                            ? '' : new Date(rowData.exittime).toLocaleString()
                        }
                      </div>
                    )}
                  </Cell>
                </Column>
              </Table>
            </Panel>
          </>
        )
      }
      <TariffOverrideModal open={open} handleClose={handleClose} rego={value} />
    </>
  )
}

export {
  CustomerSearch
}

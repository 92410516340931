/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { DatePicker, SelectPicker, Table } from 'rsuite'

import dayjs from 'dayjs'

import { getMembersReport } from '../../services/graphql/queries'
import { useApi } from '../../app/hooks'
import { DownloadTableToCsv, tableToCSV } from '../../components/DownloadTableToCsv'
import { Flag } from '../../types/enums'

const { Column, HeaderCell, Cell } = Table

const defaultColumns = [
  {
    key: 'name',
    label: 'Full name',
  },
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'subscriptions',
    label: 'Active subscriptions',
  },

  {
    key: 'status',
    label: 'Status',
  },

  {
    key: 'vehicles',
    label: 'Registered vehicles',
  },

  {
    key: 'payment',
    label: 'Last payment',
  },

  {
    key: 'login',
    label: 'Last login',
  },
]

function MembersReport () {
  const [sortColumn, setSortColumn] = React.useState('name')
  const [sortType, setSortType] = React.useState()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [status, setStatus] = React.useState<String | null>(null)
  const [payment, setPayment] = React.useState<Date | null>(null)
  const [login, setLogin] = React.useState<Date | null>(null)

  const dataQuery = useApi(getMembersReport)
  const res = dataQuery.getResponse()

  React.useEffect(() => {
    dataQuery.sendRequest({
      status,
      payment: payment ? payment.toISOString() : null,
      login: login ? login.toISOString() : null,
    })
    return () => {
      dataQuery.cleanup()
    }
  }, [status, payment, login])

  const data = res.data ? res.data.map((sub: any) => ({
    ...sub,
    payment: sub.payment ? new Date(sub.payment).toLocaleDateString() : '',
    login: sub.login ? new Date(sub.login).toLocaleDateString() : '',
  })) : []

  const getData = () => {
    if (sortColumn) {
      const sortT = sortType || 'asc'
      return data.sort((a: any, b: any) => {
        const x = a[sortColumn]
        const y = b[sortColumn]

        if ((typeof x === 'string') && (typeof y === 'string')) {
          return (sortT === 'asc') ? x.localeCompare(y) : -1 * x.localeCompare(y)
        }

        if (sortT === 'asc') {
          return x - y
        }
        return y - x
      })
    }
    return data
  }

  const handleSortColumn = (sortCol: any, sortT: any) => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      setSortColumn(sortCol)
      setSortType(sortT)
    }, 500)
  }
  const ts = dayjs(new Date()).format('YYYYMMDD-HHmm')
  const dataExport = new File(
    tableToCSV(defaultColumns, getData()),
    `MembersReport_${ts}.csv`,
    { type: 'text/plain' }
  )
  return (
    <div>
      <div style={{
        display: 'flex', gap: 12, paddingBottom: 12, position: 'relative',
      }}
      >
        <div style={{ width: 200 }}>
          Status
          <SelectPicker
            onChange={setStatus}
            value={status}
            data={(Object.keys(Flag) as (keyof typeof Flag)[]).map((key) => ({ label: key, value: key }))}
          />
        </div>
        <div style={{ width: 200 }}>
          Last payment
          <DatePicker onChange={setPayment} value={payment} />
        </div>
        <div style={{ width: 200 }}>
          Last login
          <DatePicker onChange={setLogin} value={login} />
        </div>
        <DownloadTableToCsv
          style={{ position: 'absolute', alignSelf: 'end', right: 0 }}
          url={URL.createObjectURL(dataExport)}
          fileName={dataExport.name}
          text="Download to file"
        />
      </div>

      <Table
        loading={res.loading || loading}
        hover={false}
        showHeader
        autoHeight
        data={getData()}
        sortColumn={sortColumn}
        sortType={sortType}
        defaultSortType="asc"
        onSortColumn={handleSortColumn}
        bordered
        headerHeight={40}
        rowHeight={46}
      >
        {defaultColumns.map((column) => {
          const { key, label, ...rest } = column
          return (
            <Column {...rest} key={key} flexGrow={1} fullText sortable>
              <HeaderCell>{label}</HeaderCell>
              <Cell dataKey={key} />
            </Column>
          )
        })}
      </Table>
    </div>
  )
}

export {
  MembersReport
}
